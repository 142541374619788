import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { LINK, MEDIA } from "../../../../prop_types";
import RscHubCardCoverImage from "../rsc_hb_card_cover_image/rsc_hb_card_cover_image";
import RscHbCardInner from "../rsc_hb_card_inner/rsc_hb_card_inner";
import RscHbCardWrapper from "../rsc_hub_card_wrapper/rsc_hub_card_wrapper";
import {
  bgImageStyle,
  imageCardStyle,
  imageInnerStyle,
} from "./rsc_hb_card_report_featured.module.scss";

function RscHbCardFeaturedReport({ cover, link, subtitle, title }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <RscHbCardWrapper
      isLandscape
      link={link}
      isActive={isActive}
      setIsActive={setIsActive}
      data-testid="rsc-hb-card-article-featured"
    >
      {cover && (
        <div className={imageCardStyle}>
          <div className={bgImageStyle}>
            <StaticImage
              src="../../../../../static/images/bg/grid_bg.png"
              alt="Background image"
              placeholder="none"
              layout="fullWidth"
              objectFit="cover"
            />
          </div>
          <div className={imageInnerStyle}>
            <RscHubCardCoverImage
              portrait
              hasCard
              roundTop
              roundBottom
              cover={cover}
              isActive={isActive}
            />
          </div>
        </div>
      )}
      <RscHbCardInner large {...{ title, subtitle }} />
    </RscHbCardWrapper>
  );
}

RscHbCardFeaturedReport.defaultProps = {
  category: null,
  cover: undefined,
  ctaText: "Read more",
  date: "",
  subtitle: "",
};

RscHbCardFeaturedReport.propTypes = {
  category: PropTypes.string,
  cover: MEDIA,
  ctaText: PropTypes.string,
  date: PropTypes.string,
  link: LINK.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default RscHbCardFeaturedReport;
